// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Swiper from "swiper";

const SLIDESHOW = document.querySelector(".swiper-container--slideshow");

if (SLIDESHOW && SLIDESHOW.querySelectorAll(".swiper-slide").length > 1) {
    new Swiper(SLIDESHOW, {
        autoplay: {
            delay: 15000,
        },
        effect: "fade",
        loop: true,
        navigation: {
            nextEl: SLIDESHOW.querySelector(".swiper-button--next"),
            prevEl: SLIDESHOW.querySelector(".swiper-button--prev"),
        },
        speed: 150,
    });
}


const SCROLLER = document.querySelector(".swiper-container--scroller");

if (SCROLLER) {
    let scroller_state = "mobile";

    const SCROLLER_SWIPER = new Swiper(SCROLLER, {
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        init: false,
        loop: true,
        loopedSlides: 5,
        slidesPerView: "auto",
        spaceBetween: 40,
        speed: 150,
    });

    const RECALCULATE_WIDTH = () => {
        /**
         * Only run this when recalculation is necessary...
         */
        if (window.innerWidth > 640) {
            if (scroller_state !== "desktop") {
                scroller_state = "desktop";
            } else {
                return;
            }
        } else {
            if (scroller_state !== "mobile") {
                scroller_state = "mobile";
            } else {
                return;
            }
        }

        const HEIGHT = window.innerWidth > 640 ? 65 : 45;

        const SLIDES = SCROLLER.querySelectorAll(".swiper-slide");

        SLIDES.forEach((slide) => {
            const DIMENSIONS = slide.dataset.dimensions.split("x");

            slide.style.width = `${(DIMENSIONS[0] / DIMENSIONS[1]) * HEIGHT}px`;
        });

        SCROLLER_SWIPER.update();
    };

    SCROLLER_SWIPER.on("init", () => {
        RECALCULATE_WIDTH();
    });

    SCROLLER_SWIPER.on("resize", () => {
        RECALCULATE_WIDTH();
    });

    SCROLLER_SWIPER.init();
}
